export const ADMINISTRATORS_TRANSLATE = {
	administrators: {
		messages: {
			create: "Administrador criado com sucesso",
			update: "Administrador atualizado com sucesso",
			delete: "Administrador excluído com sucesso",
		},
		dialog: {
			delete: {
				title: "Excluir",
				body: "Excluir administrador \"{{administrator.name}}\"?",
				actions: {
					primary: "Sim, excluir",
					secondary: "Voltar",
				}
			}
		},
		fields: {
			name: {
				label: "Nome",
				placeholder: "Informe aqui o nome",
			},
			email: {
				label: "E-mail",
				placeholder: "Informe aqui o e-mail"
			},
			password: {
				label: "Senha",
				placeholder: "Informe aqui a senha"
			},
			passwordConfirmation: {
				label: "Confirmação de senha",
				placeholder: "Informe aqui a mesma senha"
			},
		}
	}
}
