import { ROUTES_TRANSLATIONS } from "./routes";
import { GENERIC_TRANSLATIONS } from "./generic";
import { SIGN_IN_TRANSLATE } from "./sign-in-translate";
import { MENU_TRANSLATE } from "./menu-translate";
import { MY_PROFILE_TRANSLATE } from "./my-profle-translate";
import { HOME_TRANSLATE } from "./home-translate";
import { WELCOME_TRANSLATE } from "./welcome-translate";
import { COMPLAINTS_TRANSLATE } from "./complaints-translate";
import { FOLLOW_UP_COMPLAINS_TRANSLATE } from "./follow-up-complains-translate";
import { ACCOUNTS_TRANSLATE } from "./accounts-translate";
import { USERS_TRANSLATE } from "./users-translate";
import { CATEGORIES_TRANSLATE } from "./categories-translate";
import { COMMITTEES_TRANSLATE } from "./committees-translate";
import { FORCE_CHANGE_PASSWORD_TRANSLATE } from "./force-change-password-translate";
import { ADMINISTRATORS_TRANSLATE } from "./administrators-translate";


export const TRANSLATIONS: any = {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	"pt-BR": {
		...ROUTES_TRANSLATIONS,
		...GENERIC_TRANSLATIONS,
		...MENU_TRANSLATE,
		...SIGN_IN_TRANSLATE,
		...MY_PROFILE_TRANSLATE,
		...HOME_TRANSLATE,
		...WELCOME_TRANSLATE,
		...COMPLAINTS_TRANSLATE,
		...FOLLOW_UP_COMPLAINS_TRANSLATE,
		...ACCOUNTS_TRANSLATE,
		...USERS_TRANSLATE,
		...CATEGORIES_TRANSLATE,
		...COMMITTEES_TRANSLATE,
		...FORCE_CHANGE_PASSWORD_TRANSLATE,
		...ADMINISTRATORS_TRANSLATE
	}
}
