import { createSelector } from "@ngrx/store";

import { selectApp } from './app';
import { Menu } from "../../models/menu";
import { MenuUtils } from "../../helpers/utils/menu-utils";
import { selectIsAtAccountArea, selectIsAtAdminArea, selectUrl } from "./route-selectors";
import { selectHasPermissions } from "./user-selectors";
import { PermissionsEnum } from "../../enums/permissions.enum";

export const selectMenuState = createSelector(
    selectApp,
    (state) => state.menuState
);

export const selectMenuItems = createSelector(
    selectUrl,
    selectIsAtAdminArea,
    selectIsAtAccountArea,
    selectHasPermissions([PermissionsEnum.USER_MANAGEMENT]),
    selectHasPermissions([PermissionsEnum.CATEGORIES_MANAGEMENT]),
    selectHasPermissions([PermissionsEnum.COMMITTEES_MANAGEMENT]),
    selectHasPermissions([PermissionsEnum.COMPLAINTS_MANAGEMENT]),
    (url,
     isAtAdmin,
     isAtAcccount,
     canManageUsers,
     canManageCategories,
     canManageCommittees,
     canManageComplaints): Menu[] => {
        const menus = [
            {
                name: "menu.items.accounts.name",
                icon: "medical_information",
                group: "",
                show: isAtAdmin,
                path: "routes.admin.accounts.path"
            },
			{
				name: "menu.items.admins.name",
				icon: "medical_information",
				group: "",
				show: isAtAdmin,
				path: "routes.admin.admins.path"
			},
            {
                name: "menu.items.users.name",
                icon: "medical_information",
                group: "",
                show: isAtAcccount && canManageUsers,
                path: "routes.account.users.path"
            },
            {
                name: "menu.items.categories.name",
                icon: "category",
                group: "",
                show: isAtAcccount && canManageCategories,
                path: "routes.account.categories.path"
            },
            {
                name: "menu.items.committees.name",
                icon: "groups",
                group: "",
                show: isAtAcccount && canManageCommittees,
                path: "routes.account.committees.path"
            },
            {
                name: "menu.items.complaints.name",
                icon: "report",
                group: "",
                show: isAtAcccount && canManageComplaints,
                path: "routes.account.complaints.path"
            }
        ]
        return  MenuUtils.validateMenu(menus);
    },
);

export const selectMenusGrouped = createSelector(
    selectMenuItems,
    (menus) => {
        return MenuUtils.groupMenus(menus);
    }
);
export const selectDisplayState = createSelector(
    selectMenuState,
    state => {
        return state.displayState
    }
);


export const selectShouldBeOpen = createSelector(
    selectDisplayState,
    (state) => state === 'open'
);

export const selectRouteIsDefaultClosed = createSelector(
    () => true
);

export const selectCanISeeMenu = createSelector(
    selectMenuItems,
    (
    ) => {
        return true;
    }
);

