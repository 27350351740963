export const MENU_TRANSLATE = {
    menu: {
        notFound: {
            text: "Sem itens no menu"
        },
        loading: {
            text: "Carregando menu"
        },
        breadcrumb: {
            actions: {
                menu: {
                    text: "Menu lateral"
                }
            }
        },
        toolbar: {
            title: "",
            adminTitle: "Administrador",
            actions: {
                logout: {
                    text: "Sair"
                },
                myProfile: {
                    text: "Meu perfil"
                },
                configurations: {
                    text: "Configurações",
                    home: "Espaços",
                }
            }
        },

        // menu.group
        group: {
        },
        // menu.items
        items: {
			accounts: {
				name: "Contas",
			},
			admins: {
				name: "Administradores",
			},
            users: {
                name: "Usuários"
            },
            categories: {
                name: "Categorias"
            },
            committees:{
                name: "Comitês"
            },
            complaints: {
                name: "Denúncias"
            }
        }
    },
}
